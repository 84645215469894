import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'


import { bindActionCreators } from "redux"
//circular
import Circularprogressbar from '../../components/circularprogressbar.js'

// AOS
import AOS from 'aos'
import '../../../node_modules/aos/dist/aos'
import '../../../node_modules/aos/dist/aos.css'

//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';

//Count-up
import CountUp from 'react-countup';
// store
import { NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode, getcustomizerinfoMode, SchemeDirAction, ColorCustomizerAction, getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction, SidebarColorAction, getSidebarColorMode, getSidebarTypeMode } from '../../store/setting/setting'
import { connect } from "react-redux"


import { getSettings } from '../../services/api/setting'

import { useKeycloak } from '@react-keycloak/web'

//subheader
import SubHeader from '../../components/partials/dashboard/HeaderStyle/sub-header'


// install Swiper modules
SwiperCore.use([Navigation]);

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})



const Index = (props) => {
    const [transactionCount, setTransactionCount] = useState(0);
    const [terminalCount, setTerminalCount] = useState(0);
    const [onlineTerminalCount, setOnlineTerminalCount] = useState(0);
    const [vmcCount, setVmcCount] = useState(0);
    const [onlineVmcCount, setOnlineVmcCount] = useState(0);
    const { keycloak } = useKeycloak();
    const [isRefreshing, setIsRefreshing] = useState(false);

    const init = () => {
        if (!isRefreshing) {
            setIsRefreshing(true);
            const token = keycloak.token || '';
            getSettings(token).then((settings) => {
                settings.map((setting) => { console.log("setting:" + setting.name); sessionStorage.setItem(setting.name, setting.value); })
            });
        }

    }

    useEffect(() => {
        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

        init();

        //   customizer
        const colorcustomizerMode = sessionStorage.getItem('color-customizer-mode');
        const colorcustomizerinfoMode = sessionStorage.getItem('colorcustominfo-mode');
        const colorcustomizerprimaryMode = sessionStorage.getItem('colorcustomprimary-mode');
        if (colorcustomizerMode === null) {
            props.ColorCustomizerAction(props.customizerMode, props.cololrinfomode, props.colorprimarymode);
            document.documentElement.style.setProperty('--bs-info', props.cololrinfomode);

        }
        else {
            props.ColorCustomizerAction(colorcustomizerMode, colorcustomizerinfoMode, colorcustomizerprimaryMode);
            document.documentElement.style.setProperty('--bs-info', colorcustomizerinfoMode);

        }
    })

    return (
        <>
            <Row className="d-none d-md-block d-lg-block">
                <Col>&nbsp;</Col>
            </Row>
            <Row className="d-none d-md-block d-lg-block">
                <Col>&nbsp;</Col>
            </Row>
            <Row className="d-none d-md-block d-lg-block">
                <Col>&nbsp;</Col>
            </Row>
            <Row>
                <Col>&nbsp;</Col>
            </Row>
            <Row>

                <Col>
                    <Row className="row-cols-1">
                        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div className="container">
                                <ul className="image-gallery">
                                    <li>
                                        <img src="./Icons/Iconen_Q-Vend_Voordelen_Stijging.svg" alt="" heigth="125" width="125" />
                                        <span className="image-icon-text">Omzet</span>
                                    </li>
                                    <li>
                                        <Link className="" to="/dashboard/lists/alerts">
                                            <img src="./Icons/Iconen_Q-Vend_Meldingen_Driehoek.svg" alt="" heigth="125" width="125" />
                                            <span className="image-icon-text">Alarmen</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="" to="/dashboard/lists/transactions">
                                            <img src="./Icons/Iconen_Q-Vend_VALINA_Contactloos.svg" alt="" heigth="125" width="125" />
                                            <span className="image-icon-text">Transacties</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="" to="/dashboard/menu/settings">
                                            <img src="./Icons/Iconen_Q-Vend_Voordelen_Reparatie.svg" alt="" heigth="125" width="125" />
                                            <span className="image-icon-text">Instellingen</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12} xs={12} className="" style={{ textAlign: "center" }}>
                            <img src="./Icons/Iconen_Q-Vend_Algemeen_Golfje.svg" width="300" />
                        </Col>
                    </Row>
                </Col>

            </Row>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
